import React, { useCallback, useRef, useState } from "react"
import { GlobalHotKeys, configure } from "react-hotkeys"

import tracks from "./track_data"

import "./Player.sass"

const PLAY_PAUSE = "PLAY_PAUSE"
const STOP = "STOP"
const FAST_FORWARD = "FAST_FORWARD"
const REWIND = "REWIND"
const NEXT = "NEXT"
const PREVIOUS = "PREVIOUS"

configure({
	ignoreKeymapAndHandlerChangesByDefault: false,
})

function ProgressBar({ progress }) {
	return (
		<div className="ProgressBar">
			<hr style={{ width: `${progress}%` }} />
		</div>
	)
}

export default function Player({ onPlay, onStop }) {
	const [trackNo, setTrackNo] = useState(-1)
	const [isPlaying, setPlaying] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [progress, setProgress] = useState(0)

	const audioElements = useRef([])

	const handlePlay = useCallback(
		(trackIndex) => {
			if (
				trackIndex === undefined ||
				trackIndex < 0 ||
				trackIndex > tracks.length - 1
			) {
				trackIndex = trackNo > -1 ? trackNo : 0
			}

			setTrackNo(trackIndex)

			audioElements.current[trackIndex].play()
			audioElements.current[trackIndex].volume = tracks[trackIndex].volume

			setPlaying(true)

			onPlay && onPlay()
		},
		[trackNo, audioElements, onPlay],
	)

	const handlePause = useCallback(() => {
		audioElements.current.forEach((audioEl) => {
			audioEl.pause()
		})

		setPlaying(false)

		onStop && onStop()
	}, [audioElements, onStop])

	const handlePlayPause = useCallback(() => {
		if (isPlaying) {
			handlePause()
		} else {
			handlePlay(trackNo)
		}
	}, [isPlaying, trackNo, handlePause, handlePlay])

	const handleStop = useCallback(() => {
		audioElements.current.forEach((audioEl) => {
			audioEl.pause()
			audioEl.currentTime = 0
		})

		setPlaying(false)
		setProgress(0)
		// setTrackNo(-1)

		onStop && onStop()
	}, [onStop])

	const handleTrackClick = useCallback(
		(trackIndex, element) => {
			element && element.blur()

			if (isPlaying) {
				handleStop()
			}

			if (trackIndex !== trackNo) {
				handlePlay(trackIndex)
			}
		},
		[isPlaying, trackNo, handleStop, handlePlay],
	)

	const handleEnd = useCallback(() => {
		if (trackNo === tracks.length - 1) {
			setPlaying(false)
			setProgress(0)
			setTrackNo(-1)

			onStop && onStop()
		} else {
			handleTrackClick(trackNo + 1)
		}
	}, [trackNo, onStop, handleTrackClick])

	const handleProgress = useCallback(() => {
		if (trackNo === -1) return

		const percentagePlayed =
			(audioElements.current[trackNo].currentTime /
				audioElements.current[trackNo].duration) *
			100

		setProgress(percentagePlayed)
	}, [trackNo])

	const handleFastForward = useCallback(() => {
		if (trackNo === -1) return

		audioElements.current[trackNo].currentTime += 10
	}, [trackNo])

	const handleRewind = useCallback(() => {
		if (trackNo === -1) return

		audioElements.current[trackNo].currentTime -= 10
	}, [trackNo])

	const handleNext = useCallback(() => {
		if (trackNo === -1 || trackNo === tracks.length - 1) return

		const newTrack = trackNo + 1

		handleTrackClick(newTrack)
	}, [trackNo, handleTrackClick])

	const handlePrevious = useCallback(() => {
		if (trackNo <= 0) return

		const newTrack = trackNo - 1

		handleTrackClick(newTrack)
	}, [trackNo, handleTrackClick])

	const keyMap = {
		[PLAY_PAUSE]: ["space", "k"],
		[STOP]: ["esc", "i"],
		[FAST_FORWARD]: "l",
		[REWIND]: "j",
		[NEXT]: "o",
		[PREVIOUS]: "u",
	}

	const keyHandlers = {
		[PLAY_PAUSE]: handlePlayPause,
		[STOP]: handleStop,
		[FAST_FORWARD]: handleFastForward,
		[REWIND]: handleRewind,
		[NEXT]: handleNext,
		[PREVIOUS]: handlePrevious,
	}

	return (
		<GlobalHotKeys keyMap={keyMap} handlers={keyHandlers}>
			<div className="Player">
				<div className="tracks">
					{tracks.map((trackData, i) => (
						<button
							key={`track-btn-${i}`}
							className={
								trackNo === i ? (isLoading ? "loading" : "playing") : "loaded"
							}
							onClickCapture={(event) => handleTrackClick(i, event.target)}
						>
							<span className="title">{trackData.title.split(" ")[0]}</span>
							<span className="delimiter">{trackData.title.split(" ")[1]}</span>
							<span className="duration">{trackData.duration}</span>

							<audio
								key={`btn-track-${i}`}
								ref={(el) => {
									audioElements.current[i] = el
								}}
								src={`/audio/${tracks[i].filename}`}
								onTimeUpdate={handleProgress}
								onEnded={handleEnd}
								onLoadStart={() => setLoading(true)}
								onLoadedData={() => setLoading(false)}
								volume={tracks[i].volume || 1}
							/>
						</button>
					))}
				</div>

				<div className="copy">
					<p>
						&copy; 2025 existanding &middot; slowpocalypse ep (wip) &middot;
						v24-07-b
					</p>
					<p>
						<a target="_blank" href="https://soundcloud.com/existanding">
							soundcloud
						</a>{" "}
						&middot;{" "}
						<a target="_blank" href="https://existanding.bandcamp.com">
							bandcamp
						</a>{" "}
						{/* &middot;{" "}
						<a target="_blank" href="https://instagram.com/existanding">
							instagram
						</a>{" "} */}
						&middot;{" "}
						<a target="_blank" href="mailto:existandin@gmail.com">
							email
						</a>
					</p>
				</div>
			</div>

			<ProgressBar key={`track-progress`} progress={progress} />
		</GlobalHotKeys>
	)
}

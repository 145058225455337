const bikini = {
	title: "bikini ··········",
	duration: "4:18",
	filename: "bikini.m4a",
	volume: 0.8,
}

const radiation = {
	title: "fallout ·········",
	duration: "4:54",
	filename: "radiation.m4a",
	volume: 1,
}

const downtime = {
	title: "downtime ········",
	duration: "3:03",
	filename: "downtime.m4a",
	volume: 0.8,
}

const tickertape = {
	title: "tickertape ······",
	duration: "2:11",
	filename: "tickertape.m4a",
	volume: 0.6,
}

const slowpocalypse = {
	title: "slowpocalypse ···",
	duration: "4:31",
	filename: "slowpocalypse.m4a",
	volume: 1,
}

const constapain = {
	title: "constapain ······",
	duration: "2:48",
	filename: "constapain.m4a",
	volume: 1,
}

const magnetosphere = {
	title: "magnetosphere ···",
	duration: "4:24",
	filename: "magnetosphere.m4a",
	volume: 0.5,
}

const wipeout = {
	title: "wipeout ·········",
	duration: "3:45",
	filename: "wipeout.m4a",
	volume: 0.5,
}

const permadeath = {
	title: "permadeath ······",
	duration: "2:24",
	filename: "permadeath.m4a",
	volume: 0.75,
}

const afterlife = {
	title: "afterlife ·······",
	duration: "5:36",
	filename: "afterlife.m4a",
	volume: 0.8,
}

const tracks = [
	bikini,
	radiation,
	downtime,
	tickertape,
	slowpocalypse,
	constapain,
	magnetosphere,
	// wipeout,
	permadeath,
	afterlife,
]

export default tracks
